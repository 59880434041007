<template>
  <div>
    <Top></Top>
    <!-- 首页头部 -->
    <div class="total">
      <div class="contente">
        <!-- <Header></Header> -->
         <div class="left">
          <img src="../../assets/index/login.png" alt="" />
          <div class="ateel">
        {{searchname}}
        </div>
        </div>
        <!-- <div class="shang">上海溪南</div>
        <div class="flagship"> -->
          <!-- <el-popover
            placement="bottom-start"
            title=""
            @show="show"
            @hide="hide"
            width="200"
            trigger="hover"
          >
            <div class="pfirst">交易支持：<span>实时到账</span></div>
            <div class="psecond">买家付款后，交易实时到账</div>
            <div class="pthird">所在地区：<span>上海、上海。</span></div>
            <div class="pforth">店铺主营：<span>普卷、热轧。</span></div>
            <el-button slot="reference"
              >上海宝钢集团旗舰店<i :class="a"></i
            ></el-button>
          </el-popover> -->
        <!-- </div> -->
        <div class="center">
          <el-input
            placeholder="请输入品名、材质、规格等搜索"
            v-model="searchgoodsinput"
            clearable
          @keyup.enter.native="searchinp"
          >
          </el-input>
        <el-button type="danger" @click="searchinp" icon="el-icon-search"
              >搜索</el-button
            >
        </div>
      </div>
    </div>
<div class="ateehom">
       <div class="searmiddles">
        <ul>
          <li class="firstli" v-if="issure">
            <div>当前搜索</div>
            <div class="pointer pointerr">
              <!-- <p> 搜索：<a>宝钢<i class="el-icon-error"></i></a></p>  -->
              <p v-show="pinxina">
                品线:
                <a
                  >{{ pinxina
                  }}<i class="el-icon-error" @click="clearlineindex"></i
                ></a>
              </p>
               <p v-show="weightt">
                件重:
                <a
                  >{{ weightt
                  }}<i class="el-icon-error" @click="clearweightindex"></i
                ></a>
              </p>
              <p v-show="pinming">
                品名:
                <a
                  >{{ pinming
                  }}<i class="el-icon-error" @click="clearnameindex"></i
                ></a>
              </p>
              <p v-show="searchsteelmill.length">
                钢厂:
                <a v-for="(item, index) in searchsteelmill" :key="index"
                  >{{ item
                  }}<i class="el-icon-error" @click="clearsteelindex(index)"></i
                ></a>
              </p>
              <p v-show="searchmaterial.length">
                材质:
                <a v-for="(item, index) in searchmaterial" :key="index"
                  >{{ item
                  }}<i class="el-icon-error" @click="clearmaterindex(index)"></i
                ></a>
              </p>
              <p v-show="searchstorageplace.length">
                存放地：
                <a v-for="(item, index) in searchstorageplace" :key="index"
                  >{{ item
                  }}<i
                    class="el-icon-error"
                    @click="clearpalaceindex(index)"
                  ></i
                ></a>
              </p>
              <span class="clear" @click="clearall">清除条件</span>
            </div>
          </li>
          <li>
            <div>品线</div>
            <div class="pointer">
              <a
                v-for="(item, index) in productLine"
                @click="clickproductLine(item)"
                :key="index"
                >{{ item.name }}</a
              >
            </div>
          </li>
          <li>
            <div>品名</div>


            <div :class="uploadmore? 'pointer pointerline' :'pointer pointerline upload'">
              <span
                v-for="(item, index) in productName"
                @click="clickproductName(item)"
                :key="index"
                >{{ item.name }}</span
              >
            </div>
             <div  class="pointer">

                <span v-if="!uploadmore" @click="uploadmorea">更多</span>
                 <span v-else @click="uploadsmil">收起</span>
              </div>
          </li>

          <div v-if="serchshou">
            <li>
              <div>钢厂</div>
              <div  :class="stemuploadmore? 'pointer pointerline' :'pointer pointerline upload'" v-if="gsure">
                <span
                  v-for="(item, index) in steelmill"
                  :key="index"
                  @click="clicksteelmill(item)"
                  >{{ item.name }}</span
                >
              </div>

             <div v-if="gchoice" class="pointer">
                <el-checkbox-group v-model="searchsteelmill"  @change="ghandleCheckedCitiesChange">
                  <el-checkbox
                    v-for="item in steelmill"
                    :label="item.name"
                    :key="item.value"
                  ></el-checkbox>
                </el-checkbox-group>
              </div>

              <div v-if="gsure" class="pointer">
                <span @click="gchoise">+多选</span>
                <span v-if="!stemuploadmore" @click="steuploadmorea">更多</span>
                 <span v-else @click="setuploadsmil">收起</span>
              </div>
              <div class="gangc" v-if="gchoice">
                <span @click="gsuree">取消</span>
                <span @click="gaffirm(steelmill)">确认</span>
              </div>
            </li>
            <li>
              <div>材质</div>
              <div v-if="csure"  :class="mateuploadmore? 'pointer pointerline' :'pointer pointerline upload'">
                <span
                  v-for="(item, index) in material"
                  @click="clickmaterial(item)"
                  :key="index"
                  >{{ item.name }}</span
                >
              </div>
              <div v-if="cchoice" class="pointer">
                <el-checkbox-group v-model="searchmaterial">
                  <el-checkbox
                    v-for="item in material"
                    :label="item.name"
                    :key="item.value"
                  ></el-checkbox>
                </el-checkbox-group>
              </div>

              <div v-if="csure" class="pointer">
                <span @click="cchoise">+多选</span>
                  <span v-if="!mateuploadmore" @click="mateuploadmorea">更多</span>
                 <span v-else @click="mateuploadsmil">收起</span>
              </div>
              <div class="gangc" v-if="cchoice">
                <span @click="csuree">取消</span>
                <span @click="caffirm(material)">确认</span>
              </div>
            </li>
            <li>
              <div>存放地</div>
              <div  :class="placeuploadmore? 'pointer pointerline' :'pointer pointerline upload'" v-if="dsure">
                <span
                  v-for="(item, index) in storageplace"
                  @click="clickstorageplace(item)"
                  :key="index"
                  >{{ item.name }}</span
                >
              </div>
                 <div v-if="dchoice" class="pointer">
                <el-checkbox-group v-model="searchstorageplace">
                  <el-checkbox
                    v-for="item in storageplace"
                    :label="item.name"
                    :key="item.value"
                  ></el-checkbox>
                </el-checkbox-group>
              </div>
                <div v-if="dsure" class="pointer">
                <span @click="dchoise">+多选</span>
                 <span v-if="!placeuploadmore" @click="placeuploadmorea">更多</span>
                 <span v-else @click="placeuploadsmil">收起</span>
              </div>
              <div class="gangc" v-if="dchoice">
                <span @click="dsuree">取消</span>
                <span @click="daffirm(storageplace)">确认</span>
              </div>
            </li>
            <li>
              <div>件重</div>
              <div class="pointer">
                <a v-for="(item,index) in weight" :key="index" @click="weighte(item)">{{item.name}}</a>

              </div>
            </li>
            <li class="aaa">
              <div>规格</div>
              <div class="vvv">
                <a><el-input placeholder="1.0*1250" v-model="param.sc" @keyup.enter.native="scsure"></el-input>或</a>
                <a class="houdu"
                  ><nav>厚度：</nav>
                  <div>
                    <el-input v-model="param.ts.min" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" @keyup.enter.native="scsure"></el-input  ><span>——</span
                    ><el-input v-model="param.ts.max" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" @keyup.enter.native="scsure"></el-input></div
                ></a>
                <a class="houdu"
                  ><nav>宽度:</nav>
                  <div>
                    <el-input v-model="param.wh.min"  onkeyup="value=value.replace(/[^\d{1,}\d{1,}|\d{1,}]/g,'')" @keyup.enter.native="scsure"></el-input><span>——</span
                    ><el-input v-model="param.wh.max"  onkeyup="value=value.replace(/[^\d{1,}\d{1,}|\d{1,}]/g,'')" @keyup.enter.native="scsure"></el-input></div
                ></a>
                <a class="houdu"
                  ><nav>长度:</nav>
                  <div>
                    <el-input v-model="param.lh.min" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" @keyup.enter.native="scsure"></el-input><span>——</span
                    ><el-input v-model="param.lh.max" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" @keyup.enter.native="scsure"></el-input></div
                ></a>
                <p href="houdu"><button class="huodubuto" @click="scsure">确认</button></p>
              </div>
            </li>
          </div>
          <li class="pack" @click="pack" v-if="serchshou">
            收起<span :class="icon"></span>
          </li>
          <li class="pack" @click="opened" v-if="serchopen">
            打开<span :class="icons"></span>
          </li>
        </ul>
      </div>

  <div class="forums">
      <div class="seacontent" v-for="(item,index) in OptimumList" :key='index'>
        <div  class="firstimg">
            <div>
                       <img  class="saimage" :src="item.minImage">
              </div>
                <div class="second">
          <div class="nametext">{{item.name}}</div>
          <div>
            {{item.companyName}}<span>
              <img class="firimage" :src="item.logo" alt=""
            /></span>
          </div>
        </div>
        </div>

        <div class="third">
          <div>存放地|{{item.storageplace}}</div>

        </div>
        <div class="fourth">
          <div v-if="item.price == 0"><span>￥</span><span>电议</span></div>
          <div v-else><span>￥</span><span>{{ item.price }}</span>/吨</div>
          <!-- <div>现价：￥5600/吨</div> -->
        </div>
        <div class="fifth">{{item.createTime}}</div>
        <div class="six">
            <router-link :to="{path:'GoodsDetial',query:{id:item.id}}"> <el-button>查看详情</el-button>
            </router-link>
        </div>
      </div>

    </div>

      <!-- 分页 -->
    <!-- <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-size="pageSize"
      layout="  prev, pager, next"
      :total="totalCount"
    >
    </el-pagination> -->
       <Pagination class="el-pagination " v-show="OptimumList.length>0" :num="totalCount" :limit="pageSize" :totalPageCount="totalPageCount"></Pagination>
    </div>
    <Foo></Foo>
  </div>
</template>
<script>
import Top from "@/components/login/Top.vue";
import Foo from "@/components/login/Footer.vue";
import Header from "@/components/pagehome/searchHeader.vue";
import Pagination from "@/components/prePage/pagination.vue";
import goodsApi from "../../api/goodsApi";
// import router from '../router';
const caizhi = ["Q355A", "Q355B"];
export default {
  data() {
    return {
       placeuploadmore:false,
      mateuploadmore:false,
      stemuploadmore:false,
      uploadmore:false,
      weightt:"",
      gsure: true,
      gchoice: false,
      csure: true,
      cchoice: false,
      dsure: true,
      dchoice: false,
      // 搜索条件
      searchgoodsinput:"",
      // 搜索id
         id: this.$route.query.id,
        //  搜索名字
        searchname:this.$route.query.name,
      data: [
        { name: "综合排序", id: 0 },
        { name: "价格排序", id: 1 },
        { name: "发布日期", id: 2 },
      ],
        // 件重
      weight:[
        {name:"2吨以下",wt:{ max: 2,
          min: 0}},
        {name:"2-5吨",wt:{ max: 5,
          min: 2}},
        {name:"5-10吨",wt:{ max: 10,
          min: 5}}
      ],
      stell:"",
      param: {
        pl: "", //品线
        pn: "", //品名
        sl: [], //钢厂
        ml: [], //材质
        sp: [], //存放地
        wt: {
          //件重
          max: null, //最大值
          min: null, //最小值
        },
        ts: {
          //厚度
          max: null, //最大值
          min: null, //最小值
        },
        lh: {
          //长度
          max: null, //最大值
          min:null, //最小值
        },
        wh: {
          //宽度
          max:null, //最大值
          min: null, //最小值
        },
        sc: "", //规格文本
      },
      tabIndex: 0,
      pageNum: 1,
      pageSize: 10,
      // 总数
      totalCount: 0,
      // 总页数
      totalPageCount: 0,
      checkList: caizhi,
      showChecked: [],
      issure: false,
      icon: "el-icon-arrow-up",
      icons: "el-icon-arrow-down",
      serchshou: true,
      serchopen: false,
      pinxina: "",
      pinming: this.$route.query.pl,
      // 优选商品类表
      OptimumList: [],
      // 优选商家类表
      optiCompany:[],
      //  商品类表
      goodsList: [],
      // 钢厂
      steelmill: [],
      // 钢厂对应的材质
      material: [],
      // 商品类型
      goodstype: [],
      //  品名
      productName: "",
      // 品线
      productLine: "",
      //  存放地
      storageplace: [],

      // 搜索钢厂
      searchsteelmill: [],
      searchsteelvalue: [],
      // 搜索钢厂对应的材质
      searchmaterial: [],
      //  搜索品名
      searchproductName: "",
      // 搜索品线
      searchproductLine: "",
      //  搜索存放地
      searchstorageplace: [],
      publicer: [],
    };
  },
  created() {
      this.param.cy= this.id
    // 获取商品头部参数
    this.getGoodsParam();

    // 获取优选商品
    // this.getOptimumList()
    // 根据参数获取商品列表
    this.List();
    // 获取优选商家
    this.optimizationCompany()

  },
  methods: {
      change(even){
 this.pageNum = even
 this.List();
    },
     searchinp(){
       if(this.searchgoodsinput){
   this.$uweb.trackEvent("搜索行为","搜索",this.searchgoodsinput)
       }

      this.$router.push({path:'/search'})
    },
        // 更多品线
    uploadmorea(){
this.uploadmore = true
    },
       uploadsmil(){
this.uploadmore = false
    },
    // g更多钢厂
    steuploadmorea(){
this.stemuploadmore = true
    },
       setuploadsmil(){
this.stemuploadmore = false
    },
    // 更多材质
       mateuploadmorea(){
this.mateuploadmore = true
    },
       mateuploadsmil(){
this.mateuploadmore = false
    },
    // 更多存放第

       placeuploadmorea(){
this.placeuploadmore = true
    },
       placeuploadsmil(){
this.placeuploadmore = false
    },
       // 件重
    weighte(e){
      this.$uweb.trackEvent("商品类型筛选","点击",e.name)
       this.weightt = e.name;

      this.param.wt =e.wt
         this.issure = true;
          this.$router.push(
           {name:"AteelHome",query:{wt:e.name}}),
      this.List()
    },
    toggleTab(index) {
      this.tabIndex = index;

    },
   gsuree () {

      this.gchoice =false;
      this.gsure =true;
    },
    gchoise() {
      this.gsure = false;
      this.gchoice = true;

    },
    ghandleCheckedCitiesChange(value){

   this.searchsteelmill = value

    },
      cchoise() {
      this.cchoice = !this.cchoice;
      this.csure = !this.csure;
    },
    csuree() {
      this.csure = !this.csure;
      this.cchoice = !this.cchoice;
    },
      dchoise() {
      this.dchoice = !this.dchoice;
      this.dsure = !this.dsure;
    },
    dsuree() {
      this.dsure = !this.dsure;
      this.dchoice = !this.dchoice;
    },
     // 确认选择
    gaffirm(e) {

      for (let index = 0; index < e.length; index++) {
      const element = e[index].value;
       this.param.sl.push(element)
      }


          this.routerpush()
      this.issure = true;

      this.gchoice = !this.gchoice;
      this.gsure = !this.gsure;
    },
      // 确认选择
    caffirm(e) {
       for (let index = 0; index < e.length; index++) {
      const element = e[index].value;
       this.param.ml.push(element)
      }
       this.routerpush()
     this.issure = true;
      this.cchoice = !this.cchoice;
      this.csure = !this.csure;
    },
      // 确认选择
    daffirm(e) {
      for (let index = 0; index < e.length; index++) {
      const element = e[index].value;
       this.param.sp.push(element)
      }
       this.routerpush()
    this.issure = true;
      this.dchoice = !this.dchoice;
      this.dsure = !this.dsure;
    },
    // 确认规格
    scsure(){
       this.$uweb.trackEvent("商品类型筛选","点击","规格")
          this.routerpush()

     if(!this.param.sc){
      delete  this.param.sc;
      }
       if(!this.param.ts.max){
      delete  this.param.ts.max;
      } if(!this.param.ts.min){
      delete  this.param.ts.min;
      } if(!this.param.lh.max){
      delete  this.param.lh.max;
      } if(!this.param.lh.min){
      delete  this.param.lh.min;
      } if(!this.param.wh.max){
      delete  this.param.wh.max;
      }
       if(!this.param.wh.min){
      delete  this.param.wh.min;
      }




      this.List();
    },
    pack() {
      this.icon = "el-icon-arrow-down";
      this.serchshou = !this.serchshou;
      this.serchopen = !this.serchopen;
    },
    opened() {
      this.icon = "el-icon-arrow-up";
      this.serchshou = !this.serchshou;
      this.serchopen = !this.serchopen;
    },
    // 获取商品参数
    getGoodsParam() {
      goodsApi.getGoodsParam(
        { pageNum: this.pageNum, pageSize: this.pageSize },
        (res) => {
          if (res.data.code != 200) {
            this.$messsge.error("获取数据失败");
          } else {
            this.goodsList = res.data.result;
            this.productLine = this.goodsList.goodstype.productLine;
            this.productName = this.goodsList.goodstype.productName;
            this.steelmill = this.goodsList.steelmill;
            this.material = this.goodsList.material;
            this.storageplace = this.goodsList.storageplace;
            // 通过品线获取品名
            for (let index = 0; index < this.productName.length; index++) {
              const element = this.productName[index];
              this.publicer.push(element);
            }

          }
        }
      );
    },
    // 获取优选商品
    getOptimumList() {
      goodsApi.getOptimumList(
        { pageNum: this.pageNum, pageSize: this.pageSize },
        (res) => {

        }
      );
    },
// 获取优选商家
optimizationCompany(){
    goodsApi.optimizationCompany("",res=>{

      if(res.data.code==200){
         this.optiCompany = res.data.result
      }

    })
},
    // 根据参数获取商品列表
    List() {
      var search= { pageNum: this.pageNum, pageSize: this.pageSize,params:encodeURIComponent(JSON.stringify(this.param))};

      goodsApi.List(
       search,
        (res) => {

          this.OptimumList = res.data.result.data;

          this.totalCount = res.data.result.totalCount;
          this.totalPageCount = res.data.result.totalPageCount;

          for (let index = 0; index < this.OptimumList.length; index++) {
            this.OptimumList[index].createTime = this.$moment(
              this.OptimumList[index].createTime
            ).format("yyyy/MM/DD ")
          }
        }
      );
    },
    //监听pagesize改变事件
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.List();
    },
    //监听pageNum改变事件
    handleCurrentChange(newPage) {
      this.pageNum = newPage;
      this.List();
    },
    // 点击每一个重新渲染页面
      routerpush(){
          this.$router.push(
           {name:"AteelHome",query:this.param},
            onComplete => {},
            onAbort => {}
         )
      },
    // 清楚某一个
    clearlineindex() {
      this.pinxina = "";
        delete this.param.pl;
      this.clickclear();
        this.$router.push(
           {name:"AteelHome",query:this.param},
            onComplete => {},
            onAbort => {}
         )
      this.List();
    },
    clearnameindex() {
      this.pinming = "";
        delete this.param.pn;
      this.clickclear();
       this.routerpush()
      this.List();
    },
         clearweightindex() {
      this.weightt = "";
       delete this.param.wt;
      this.clickclear();

      this.List();
    },
    clearsteelindex(index) {
      this.searchsteelmill.splice(index, 1);
      this.param.sl.splice(index, 1);


      this.clickclear();
        this.routerpush()
      this.List();
    },
    clearmaterindex(index) {
      this.searchmaterial.splice(index, 1);
       this.param.ml.splice(index, 1);

      this.clickclear();
         this.routerpush()
      this.List();
    },
    clearpalaceindex(index) {
      this.searchstorageplace.splice(index, 1);
       this.param.sp.splice(index, 1);

      this.clickclear();
         this.routerpush()
      this.List();
    },
    // 清楚全部
    clearall() {

      // this.param={}
      this.pinxina = "";
      this.pinming = "";
      this.searchsteelmill = [];
      this.searchmaterial = [];
      this.searchstorageplace = [];
        this.$router.push(
           {name:"AteelHome",query:{id:this.id,name:this.searchname}},
            onComplete => {},
            onAbort => {}
         )
         this.$router.go(0)
      this.productName = this.goodsList.goodstype.productName;

      this.issure = false;
         this.List();
    },
    // 全部消失
    clickclear() {
      if (
        !this.pinxina &&
        !this.pinming &&
        !this.searchsteelmill.length &&
        !this.searchmaterial.length &&
        !this.searchstorageplace.length
      ) {
             this.$router.push(
            {name:"AteelHome",query:{id:this.id,name:this.searchname}},
            onComplete => {},
            onAbort => {}
         )
             this.$router.go(0)
        this.issure = false;
        this.productName = this.goodsList.goodstype.productName;
      }

      this.List();
    },
    // 点击品线
    clickproductLine(e) {
       this.$uweb.trackEvent("商品类型筛选","点击",e.name)

      var imn = this.publicer.filter((item) => {
        return e.value == item.pid;
      });
      this.productName = imn;
      this.pinxina = e.name;
      this.param.pl = e.value;
      //  this.$router.push({name:"AteelHome",query:this.param})
       this.routerpush()
      this.issure = true;

      this.List();


    },
    // 点击品名
    clickproductName(e) {
    this.$uweb.trackEvent("商品类型筛选","点击",e.name)
      this.pinming = e.name;
      this.param.pn = e.value;
       this.routerpush()
      this.issure = true;

      this.List();
    },
    // 点击钢厂
    clicksteelmill(e) {
      this.$uweb.trackEvent("商品类型筛选","点击",e.name)
      if (  this.searchsteelmill.length > 0) {
        let alike=false;
        for (let index = 0; index <   this.searchsteelmill.length; index++) {
            const element =   this.searchsteelmill[index];
         
            if (element == e.name) {
                alike=false;
                break;
            }else if(index==  this.searchsteelmill.length-1 && element != e.name){
                alike=true;
            }
        }
        if(alike){
            this.searchsteelmill.push(e.name)

      this.param.sl.push(e.value)
        }
    } else {
       this.searchsteelmill.push(e.name)

      this.param.sl.push(e.value)
    }

       this.routerpush()
      this.issure = true;

      this.List();
    },
    // 点击材质
    clickmaterial(e) {
       this.$uweb.trackEvent("商品类型筛选","点击",e.name)
      if (    this.searchmaterial.length > 0) {
        let alike=false;
        for (let index = 0; index <     this.searchmaterial.length; index++) {
            const element =     this.searchmaterial[index];
          
            if (element == e.name) {
                alike=false;
                break;
            }else if(index==    this.searchmaterial.length-1 && element != e.name){
                alike=true;
            }
        }
        if(alike){
           this.searchmaterial.push(e.name);
      this.param.ml.push(e.value)
        }
    } else {
     this.searchmaterial.push(e.name);
      this.param.ml.push(e.value)
    }
       this.routerpush()
      this.issure = true;
      this.List();
    },
    // 点击存放地
    clickstorageplace(e) {
       this.$uweb.trackEvent("商品类型筛选","点击",e.name)
      if (this.searchstorageplace.length > 0) {
        let alike=false;
        for (let index = 0; index < this.searchstorageplace.length; index++) {
            const element = this.searchstorageplace[index];
         
            if (element == e.name) {
                alike=false;
                break;
            }else if(index==this.searchstorageplace.length-1 && element != e.name){
                alike=true;
            }
        }
        if(alike){
            this.searchstorageplace.push(e.name);
            this.param.sp.push(e.value)
        }
    } else {
        this.searchstorageplace.push(e.name);
        this.param.sp.push(e.value)
    }
       this.routerpush()
      this.issure = true;
      this.List();
    },
  },
  components: {
    Top,
    Foo,
    Header,
    Pagination
  },
};
</script>
<style lang='scss' scope>
.ateehom{
  min-height: 700px;
  background-color: rgb(251,251,251);
}
.el-pagination {
   width: 100% !important;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 20px;

}
.total {
  border-bottom: 1px solid #e95157;
}
.contente{
  width: 60%;
  height: 99px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 740px;
  .left {
    box-sizing: border-box;
    // width: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 0px 13px 0px 14px;
    //  border: 1px solid red;
    img {
      width: 140px;
      height: 70px;
      vertical-align: middle;
      //   padding-left: 30px;
      // border: 1px solid red;
    }
  }
  .ateel{
    font-size: 22px;
color: #333333;
margin-left: 20px;

  }
  .center {
    width: 500px;
    box-sizing: content-box;
    margin-left: 46px !important;
    height: 40px;
    border-radius: 3px;
    border: 2px solid #e94f55;
    display: flex;
    justify-content: start;
    flex-wrap: nowrap;
    .el-input {
      border: none;
    }

    .el-button--danger {
      border-radius: 0px;
      font-size: 16px;
      color: #e94f55ff;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .right1 {
    width: 138px;
    display: flex;
    padding-left: 29px;
    flex-wrap: wrap;
    // border: 1px solid red;

    .top {
      text-align: center;
      height: 22px;
      width: 120px;
      line-height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.56);
    }
  }
  .shang {
    width: 40px;
    height: 32px;
    text-align: center;
    background: rgb(230, 8, 19);
    border-radius: 2px;
    color: white;
    margin-left: 50px;
  }
  .flagship {
    width: 290px;
    color: #333333;
    font-size: 16px;
    margin-left: 10px;
    cursor: pointer;
  }
}

.searmiddles {
  margin-top: 30px;
  // width: 1200px;
  width: 60%;
  // margin-left: 400px;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 1200px;
  .aaa {
   a:nth-of-type(1) {
      width: 154px;
      float: left;
      margin-top: -5px;
      .el-input__inner {
        border: 1px solid rgba(0, 0, 0, 0.2);
        width: 100px;
        height: 28px;
        margin-left: -20px;
      }
    }
    .vvv {
      display: flex;
      flex-direction: row;
      margin-top: 5px;
      // margin-right: 5px;
      // border: 1px solid red;
    }
       .huodubuto{
         position: relative;
       width: 60px;
      //  border: 1px solid red;
       height: 30px;
       line-height: 17px;
       color: rgb(75, 61, 61);
        border: 1px solid rgba(0, 0, 0, 0.2);
         border-radius: 4px;
         top: -3px;
left: 70px;
}
    .houdu {
      width: 240px;
      display: flex;
      flex-direction: row;
      color: rgba(0, 0, 0, 0.4);
      line-height: 30px;

      nav{
        // border: 1px solid red;
        width: 60px;
        position: absolute;
      }
      span {
        color: rgba(0, 0, 0, 0.4);
        line-height: 30px;
      }
      div {
        display: flex;
        flex-direction: row;
      }
      .el-input__inner {
        width: 70px;
        height: 28px;
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
  }
  .el-checkbox-group {
    width: 880px !important;
    // display: flex;
    // justify-content: space-between;
    // border: 1px solid red;
    // overflow: hidden;

  }
  .gangc span:nth-of-type(2) {
    color: white;
    background: #e94f55;
    cursor: pointer;
  }
  .gangc span:nth-of-type(1):hover {
    color: #e94f55;
    border: #e94f55 1px solid;
    cursor: pointer;
  }
  .gangc span:nth-of-type(2):hover {
    color: white;
  }
  .firstli a {
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.56);
    padding-left: 5px;
    padding-right: 5px;
  }
  .clear {
    margin-left: 30px;
  }
  //  ul {
  //   min-width: 1000px;
  //  }

  ul li {
    list-style: none;
    // height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    // border: 1px solid red;
  }

  .pointer {
    a {
      cursor: pointer;
      border: 1px solid transparent;
      // margin-left: 20px;
      margin-right: 10px;
      font-size: 12px;
    }
    span {
       font-size: 12px;
      cursor: pointer;
    }
  }
 .pointer.pointerline {

    span{
       font-size: 12px;
       cursor: pointer;
      border: 1px solid transparent;
      // margin-left: 20px;
      margin-right: 10px;
        display: inline-block;
    }

 }
 .pointer.pointerline.upload{
 height: 40px;
   overflow: hidden;
 }
  .pointer.pointerr {
    a {
      // border: 1px solid red;
      margin: 0;
    }
    p {
      display: inline-block;
      margin-left: 10px;
    }
  }

  ul li div:nth-of-type(1) {
    width: 90px;
    // height: 28px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.56);
    line-height: 38px;
    margin-left: 20px;
  }
  ul li div:nth-of-type(2) {
    width: 880px;
    // height: 28px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    line-height: 38px;
  }
  ul li div:nth-of-type(2) a {
    // margin-left: 20px;
  }
  ul li div:nth-of-type(3) {
    display: flex;
    flex-direction: row;
  }
  ul li div:nth-of-type(3) span {
    width: 63px;
    height: 24px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    line-height: 23px;
    text-align: center;
    margin-top: 7px;
    color: rgba(0, 0, 0, 0.56);
    margin-left: 8px;
  }
  ::v-deep .el-input__inner {
    height: 24px;
    margin-left: -20x;
  }
}
// --------------------
.pfirst,
.pthird,
.pforth {
  color: #333333;
  font-size: 12px;
  line-height: 20px;
}
.psecond,
.pthird span,
.pforth span {
  color: darkgray;
  font-size: 10px;
}
.pfirst span {
  width: 59px;
  height: 20px;
  background: linear-gradient(315deg, #e32531 0%, #fe7378 100%);
  border-radius: 2px;
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
}
.searmiddle div:nth-of-type(3) span:hover {
  color: #e94f55;
  border: #e94f55 1px solid;
  cursor: pointer;
}
.pack {
  height: 38px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.56);
  line-height: 38px;
  padding-left: 50%;
  cursor: pointer;
}
.pack span {
  line-height: 38px;
  margin-left: 10px;
}
.forums {
  width: 60%;
  margin: 0 auto;
  margin-top: 30px;
  min-width: 1200px;
}
.seacontent {
display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
box-sizing: border-box;
  width:100%;
  height: 100px;
  padding: 10px;
 background-color: white;

.saimage {
  width: 80px;
  height: 80px;

}
   .firstimg{
     display: flex;
     justify-content: space-between;
    align-items: center;
  }
.second {
  margin-left: 10px;
  width: 230px;
  .nametext{
     overflow: hidden !important;
       white-space: nowrap !important;
        text-overflow: ellipsis !important
    }
  .firimage {
    margin-left: 8px;
    width: 15px;
    position: relative;
    top: 3px;
  }
}
.second div:nth-of-type(1) {
  // width: 213px;
  height: 22px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  line-height: 22px;
  margin-top: 10px;
}
.second div:nth-of-type(2) {
  // width: 213px;
  // height: 14px;
  font-size: 10px;
  font-weight: 300;
  color: rgba(0, 0, 0, 1.6);
  line-height: 14px;
  margin-top: 10px;
}

.third {
  margin-left: 70px;
  margin-top: 20px;
}
.third div:nth-of-type(1) {

  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  line-height: 22px;
}
.third div:nth-of-type(2) {

  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.56);
  line-height: 20px;
  text-align: center;
}
.fourth {
  margin-left: 70px;
  margin-top: 10px;
}
.fourth div:nth-of-type(2) {

  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.56);
  line-height: 20px;
}
.fourth div:nth-of-type(1) span:nth-of-type(1) {

  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #d0111a;
  line-height: 20px;
}
.fourth div:nth-of-type(1) span:nth-of-type(2) {

  height: 37px;
  font-size: 20px;
  font-weight: 600;
  color: #d0111a;
  line-height: 37px;
}
.fifth {

  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 20px;
  margin-left: 70px;
}
.six {
  margin-top: 10px;
  margin-left: 70px;
}
.six .el-button {

  height: 45px;
  border-radius: 5px;
  border: 1px solid #e94f55;
  color: #e94f55;
}
.six .el-button:hover {
  background: #e94f55;
  color: white;
}
}

</style>
